/* eslint-disable max-len */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/no-multi-comp */
import * as React from 'react';
import PropTypes from 'prop-types';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import SelectPlataform from './SelectPlataform';
import tiger from '../../images/tiger.png';
import mouse from '../../images/mouse.png';

function CircularProgressWithLabel(props) {
  return (
    <div>
      <p className="lg:text-[35px] text-[25px] font-custom text-center">
        ATIVANDO
        {' '}
        <span className="text-yellow-300">HACKER RPT</span>
      </p>
      <div className="flex justify-center items-center">
        <img src={ tiger } alt="tiger" className="w-24 mx-4" />
        <Box sx={ { position: 'relative', display: 'inline-flex' } }>
          <CircularProgress variant="determinate" { ...props } size={ 120 } color="inherit" />
          <Box
            sx={ {
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            } }
          >
            <Typography
              variant="caption"
              component="div"
              sx={ { color: 'text.secondary', fontSize: '35px' } }
            >
              {`${Math.round(props.value)}%`}
            </Typography>
          </Box>
        </Box>
        <img src={ mouse } alt="mouse" className="w-24 mx-4" />
      </div>
    </div>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function CircularWithValueLabel() {
  const [progress, setProgress] = React.useState(10);
  const [showCompleteMessage, setShowCompleteMessage] = React.useState(false);

  React.useEffect(() => {
    const tempo = () => {
      if (progress < 20 || progress > 90) {
        return 200;
      }
      return 50;
    };
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress >= 100 ? 100 : prevProgress + 1));
    }, tempo());

    return () => {
      clearInterval(timer);
    };
  }, [progress]);

  React.useEffect(() => {
    // Quando o progresso atinge 100, aguarda 3 segundos antes de exibir a mensagem
    if (progress === 100) {
      const timeout = setTimeout(() => {
        setShowCompleteMessage(true);
      }, 1000);

      return () => clearTimeout(timeout); // Limpa o timeout ao desmontar
    }
  }, [progress]);

  if (showCompleteMessage) {
    return (
      <div>
        <SelectPlataform />
      </div>
    );
  }

  return <CircularProgressWithLabel value={ progress } />;
}
