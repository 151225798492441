/* eslint-disable no-magic-numbers */
import React from 'react';
import logopix from '../../images/logopix.png';
import { nomes, nomesJogos } from './Nomes';

export default function Ganhos() {
  const gerarValorAleatorio = () => {
    const numeroAleatorio = Math.floor(Math.random() * nomes.length);
    const numeroAleatorioJogos = Math.floor(Math.random() * nomesJogos.length);
    const jogo = nomesJogos[numeroAleatorioJogos];
    const valorInteiro = Math.random() * (1800 - 230) + 230;
    const valorComCentavos = valorInteiro.toFixed(2);
    return { nome: nomes[numeroAleatorio], valorComCentavos, jogo };
  };

  const nomeEGanho = () => {
    const newMap = [];
    for (let i = 0; i < 200; i += 1) { // Ajustado para 200 itens
      const newNome = gerarValorAleatorio();
      newMap.push(newNome);
    }
    return newMap;
  };

  return (
    <div className="overflow-hidden scroll-container w-full h-4 my-2">
      <div className="flex items-start animate-scroll space-x-4 w-[7000px]">
        {nomeEGanho().map((e, i) => (
          <div key={ i } className="flex scroll-item">
            <img
              className="w-3 h-3 mr-1 mt-0.5"
              src={ logopix }
              alt="logo-pix"
            />
            <p className="text-[12px]">
              {`${e.nome} - ${e.jogo} - `}
              <span className="text-green-600 font-bold mr-8">
                {`R$ ${e.valorComCentavos}`}
              </span>
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}
