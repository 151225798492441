/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, { useState } from 'react';
import { Alert, Button, ButtonGroup, Snackbar, ThemeProvider, createTheme } from '@mui/material';
import ImagesArrPg from './ImagesArrPg';
import ImagesArrPragmatic from './ImagesArrPragmatic';
import UsuariosUtilizando from './UsuariosUtilizando';

export default function SelectPlataform() {
  const [plataform, setPlatafomr] = useState(0);
  // eslint-disable-next-line no-unused-vars
  const [open, setOpen] = useState(true);

  const theme = createTheme({
    palette: {
      btnColor: {
        main: '#ffef00',
      },
      btnColorOff: {
        main: '#eeef00',
      },
    },
  });

  const pg = () => {
    setPlatafomr(0);
  };

  const pragmatic = () => {
    setPlatafomr(1);
  };

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <ThemeProvider theme={ theme }>
      <p className="lg:text-[35px] text-[25px] font-custom text-center">
        <span className="text-green-400">HACKER RPT</span>
        {' '}
        ATIVO
      </p>
      <div>
        <Snackbar open={ open } autoHideDuration={ 5000 } onClose={ handleClose }>
          <Alert
            onClose={ handleClose }
            severity="success"
            variant="filled"
            sx={ { width: '100%' } }
          >
            HACKER RPT ATIVADO COM SUCESSO!
          </Alert>
        </Snackbar>
      </div>
      <div
        className="bg-violet-700 flex
      flex-col items-center justify-center p-b w-full"
      >
        {/* <ImagesArrPragmatic />
      <ImagesArrPg /> */}
        <ButtonGroup
          className="w-full flex justify-center mb-3"
          aria-label="Basic button group"
        >
          <Button
            className={ `${plataform === 0 ? 'text-black' : ''} md:w-[300px] w-[40%]` }
            variant={ `${plataform === 0 ? 'contained' : 'outlined'}` }
            color={ `${plataform === 0 ? 'btnColor' : 'btnColorOff'}` }
            onClick={ pg }
          >
            PGSoft
          </Button>
          <Button
            className={ `${plataform === 1 ? 'text-black' : ''} md:w-[300px] w-[40%]` }
            variant={ `${plataform === 1 ? 'contained' : 'outlined'}` }
            color={ `${plataform === 1 ? 'btnColor' : 'btnColorOff'}` }
            onClick={ pragmatic }
          >
            Pragmatic
          </Button>
        </ButtonGroup>
        <UsuariosUtilizando />
        { plataform === 0 ? (
          <ImagesArrPg />
        ) : (
          <ImagesArrPragmatic />
        )}
      </div>
    </ThemeProvider>
  );
}
