/* eslint-disable max-len */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useState } from 'react';

export default function UsuariosUtilizando() {
  const [totalUsers, setTotalUsers] = useState(0);
  useEffect(() => {
    const usersStorage = localStorage.getItem('total-users');
    if (!usersStorage) {
      const numeroAleatorio = Math.floor(Math.random() * (120001 - 100000) + 100000);
      localStorage.setItem('total-users', numeroAleatorio);
    }
    if (usersStorage) {
      const numeroAleatorio = Math.floor(Math.random() * (6001 - 3000) + 3000);
      if ((numeroAleatorio % 2 === 0) && usersStorage > 110000) {
        localStorage.setItem('total-users', Number(usersStorage) - numeroAleatorio);
      } else {
        localStorage.setItem('total-users', Number(usersStorage) + numeroAleatorio);
      }
    }
    setTotalUsers(localStorage.getItem('total-users'));
  }, []);
  return (
    <div className="text-white">
      <p className="text-center">
        Tem
        <span className="text-[#ffef00]">
          {' '}
          {totalUsers}
        </span>
        {' '}
        jogadores on-line usando nossa ferramenta.
      </p>
    </div>
  );
}
