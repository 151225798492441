/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React, { useContext, useEffect } from 'react';
import Header from './components/Header';
// import SelectPlataform from './components/SelectPlataform';
import AppContext from '../context/AppContext';
import { imagesTwo } from './components/ImagesArrPragmatic';
import { imagesTwoo } from './components/ImagesArrPg';
import CircularWithValueLabel from './components/Loading';
import ScrollToTop from './ScrollToTop';

export default function RtpSlots() {
  const { setJogos, setHorario } = useContext(AppContext);

  useEffect(() => {
    const time = localStorage.getItem('time-jogos');
    if (!time) {
      const date = new Date();
      const hora = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
      const minuto = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
      localStorage.setItem('time-jogos', date.getTime());
      setHorario(`${hora}:${minuto}`);
    }
    if (time) {
      const date = new Date();
      const dateOne = Number(time);
      const dateTwo = date.getTime();
      const result = dateTwo - Number(dateOne);
      const hora = date.getHours() < 10 ? `0${date.getHours()}` : date.getHours();
      const minuto = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes();
      setHorario(`${hora}:${minuto}`);
      if (result > 300000) {
        localStorage.removeItem('number-jogos');
        localStorage.setItem('time-jogos', dateTwo);
        setHorario(`${hora}:${minuto}`);
      } else {
        const dateAnterior = new Date(Number(time));
        const horaAnt = dateAnterior.getHours() < 10 ? `0${dateAnterior.getHours()}` : dateAnterior.getHours();
        const minutoAnt = dateAnterior.getMinutes() < 10 ? `0${dateAnterior.getMinutes()}` : dateAnterior.getMinutes();
        setHorario(`${horaAnt}:${minutoAnt}`);
      }
    }

    const local = localStorage.getItem('number-jogos');

    if (!local) {
      const itensOne = [];
      const itensTwo = [];
      for (let i = 0; i < imagesTwo.length; i += 1) {
        const numberOne = Math.floor(Math.random() * 10);
        const numberTwo = Math.floor(Math.random() * 10);
        const number = Math.floor(Math.random() * (99 - 12 + 1)) + 12;
        itensTwo.push({ number, numberOne, numberTwo });
      }
      for (let i = 0; i < imagesTwoo.length; i += 1) {
        const numberOne = Math.floor(Math.random() * 10);
        const numberTwo = Math.floor(Math.random() * 10);
        const number = Math.floor(Math.random() * (99 - 12 + 1)) + 12;
        itensOne.push({ number, numberOne, numberTwo });
      }

      const arrayString = JSON.stringify({ itensOne, itensTwo });

      localStorage.setItem('number-jogos', arrayString);
      setJogos({ itensOne, itensTwo });
    }

    if (local) {
      const arrayRecuperado = JSON.parse(local);
      setJogos(arrayRecuperado);
    }
  }, [setJogos, setHorario]);

  return (
    <div className="bg-violet-700 text-white h-screen">
      <ScrollToTop />
      <Header />
      <div className="bg-violet-700 h-full font-custom pt-4 mt-[160px] lg:mt-[170px]">
        <div className="w-full flex justify-center">
          <CircularWithValueLabel />
        </div>
        <div className="mt-10 text-justify px-2">
          <p>
            Esse Site NÃO é do Google. Esse site NÃO faz parte do Google. Além disso, esse site NÃO é endossado pelo Google de nenhuma maneira.
          </p>
          <p>
            Aviso Legal: Os resultados referentes ao jogo foram comprovados por nossos clientes. Não garantimo resultados iguais para todos, Variam de pessoas para pessoas dependendo de diversos fatores individuais. Todo resultado é de inteira responsabilidade do cliente. Essa Página/Método não é veiculado ao Google, Instagram, Facebook e TikTok.
          </p>
        </div>
      </div>
    </div>
  );
}
