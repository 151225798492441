/* eslint-disable no-unused-vars */
/* eslint-disable react-func/max-lines-per-function */
/* eslint-disable sonarjs/cognitive-complexity */
/* eslint-disable sonarjs/no-duplicate-string */
/* eslint-disable no-magic-numbers */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { Button } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Header from './components/Header';
import ScrollToTop from './ScrollToTop';

export default function Home() {
  const history = useHistory();

  return (
    <div className="bg-violet-700 text-white h-full min-h-screen">
      <ScrollToTop />
      <Header />
      <div className="bg-violet-700 h-full font-custom pt-4">
        <div
          className="mt-[160px] lg:mt-[170px] text-center lg:text-[35px] text-[25px] mb-4 px-2 "
        >
          <p className="pulsate-text">
            Descubra agora o novo Hacker do momento
          </p>

          <p>
            TESTADOS E APROVADOS E COM RTP
            <span className="text-yellow-300"> "PORCENTAGEM"</span>
            {' '}
            DE SLOTS ATUALIZADA EM TEMPO REAL DOS SLOTS QUE MAIS PAGAM NAS PLATAFORMAS NOVAS
          </p>
          <p>
            Clique em
            {' '}
            <span className="text-red-500">
              ATIVAR HACKEAR RTP
            </span>
            {' '}
            Para saber em tempo real qual a porcentagem do slot que está pagando mais no momento dos maiores lançamentos de plataformas novas de 2024
          </p>
          <Button
            variant="contained"
            color="success"
            onClick={ () => history.push('/hacker-rpt') }
            sx={ {
              fontSize: '25px',
              animation: 'pulsate 1s infinite',
              '@keyframes pulsate': {
                '0%': { boxShadow: '0 0 10px 5px rgba(255, 255, 255, 0.6)' },
                '50%': { boxShadow: '0 0 20px 10px rgba(255, 255, 255, 0.8)' },
                '100%': { boxShadow: '0 0 10px 5px rgba(255, 255, 255, 0.6)' },
              },
            } }
          >
            ATIVAR HACKER RTP
          </Button>
        </div>
        <div className="mt-10 text-justify px-2">
          <p>
            Esse Site NÃO é do Google. Esse site NÃO faz parte do Google. Além disso, esse site NÃO é endossado pelo Google de nenhuma maneira.
          </p>
          <p>
            Aviso Legal: Os resultados referentes ao jogo foram comprovados por nossos clientes. Não garantimo resultados iguais para todos, Variam de pessoas para pessoas dependendo de diversos fatores individuais. Todo resultado é de inteira responsabilidade do cliente. Essa Página/Método não é veiculado ao Google, Instagram, Facebook e TikTok.
          </p>
        </div>
      </div>
    </div>
  );
}
