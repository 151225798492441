/* eslint-disable max-len */
export const nomes = [
  'Thiago Souza', 'Gabriela Lima', 'João Rodrigues', 'Isabela Fernandes', 'Lucas Oliveira',
  'Mariana Silva', 'Rafael Santos', 'Ana Clara Costa', 'Pedro Almeida', 'Camila Ribeiro',
  'Bruno Barbosa', 'Juliana Martins', 'Carlos Pereira', 'Fernanda Araújo', 'Diego Lima',
  'Larissa Mendes', 'Daniel Carvalho', 'Letícia Santos', 'Felipe Rocha', 'Amanda Sousa',
  'Gustavo Nascimento', 'Sofia Monteiro', 'Leonardo Dias', 'Vinícius Moraes', 'Beatriz Gomes',
  'Rodrigo Albuquerque', 'Cecília Duarte', 'Arthur Henrique', 'Patrícia Menezes', 'Caio Neves',
  'Lívia Costa', 'André Teixeira', 'Natália Rezende', 'Eduardo Castro', 'Bruna Freitas',
  'Ricardo Farias', 'Manuela Barros', 'Victor Ramos', 'Melissa Souza', 'Igor Rocha',
  'Luiza Carvalho', 'Henrique Martins', 'Viviane Lima', 'Otávio Silva', 'Nicole Marques',
  'Fernando Pereira', 'Bárbara Mendes', 'Fábio Rodrigues', 'Elisa Monteiro', 'Samuel Ferreira',
  'Flávia Santana', 'Miguel Cardoso', 'Renata Campos', 'Rogério Oliveira', 'Evelyn Fernandes',
  'Marcelo Almeida', 'Clara Duarte', 'José Almeida', 'Raquel Souza', 'Antônio Martins',
  'Catarina Ribeiro', 'Paulo César', 'Alana Moreira', 'Jorge Costa', 'Helena Mendes',
  'Vitor Santos', 'Marina Nogueira', 'Gabriel Alves', 'Simone Pereira', 'Matheus Fernandes',
  'Priscila Mendes', 'Jonas Freitas', 'Regina Araújo', 'Maurício Gomes', 'Alice Teixeira',
  'Julio César', 'Lorena Nunes', 'Guilherme Moreira', 'Eliane Rocha', 'Murilo Fernandes',
  'Diana Costa', 'Luiz Eduardo', 'Clarissa Pereira', 'Renato Araújo', 'Érica Santana',
  'Enzo Ribeiro', 'Bianca Martins', 'Aline Carvalho', 'Luana Almeida', 'Anderson Lima',
  'Tatiane Monteiro', 'Francisco Nogueira', 'Joana Freitas', 'Adriana Oliveira', 'Lucas Martins',
  'Natasha Duarte', 'José Carlos', 'Kelly Lima', 'Sérgio Barbosa', 'Melissa Costa',
  'Marcelo Nunes', 'Patrícia Ribeiro', 'Miguel Fernandes', 'Michele Rocha', 'André Oliveira',
  'Carolina Almeida', 'Alexandre Pereira', 'Letícia Silva', 'Eduardo Fernandes', 'Vivian Castro',
  'Marcos Paulo', 'Raíssa Costa', 'Luiz Felipe', 'Vanessa Nunes', 'Rafael Teixeira',
  'Verônica Sousa', 'Gustavo Pereira', 'Lilian Araújo', 'Lucas Cardoso', 'Denise Martins',
  'Pedro Henrique', 'Larissa Almeida', 'Júlio Nascimento', 'Sabrina Nogueira', 'Carlos Alberto',
  'Monique Ribeiro', 'Márcio Souza', 'Bruna Fernandes', 'Roberto Martins', 'Yasmin Castro',
  'Hugo Oliveira', 'Rosana Lima', 'Vitor Mendes', 'Valéria Costa', 'Cauã Pereira',
  'Larissa Santos', 'Sérgio Farias', 'Regina Duarte', 'Felipe Oliveira', 'Juliana Costa',
  'Diego Almeida', 'Natália Silva', 'Ricardo Lima', 'Eliane Souza', 'Fernando Araújo',
  'Ana Paula', 'Gustavo Nunes', 'Milena Carvalho', 'Anderson Duarte', 'Camila Silva',
  'Gabriel Martins', 'Tatiane Souza', 'Luiz Fernando', 'Isabela Nunes', 'Mário Ferreira',
  'Aline Teixeira', 'Rodrigo Souza', 'Suelen Silva', 'Leandro Ribeiro', 'Daniele Araújo',
  'Victor Hugo', 'Bianca Nogueira', 'Maurício Lima', 'Nicole Freitas', 'João Pedro',
  'Viviane Almeida', 'Cláudio Mendes', 'Renata Pereira', 'Mateus Silva', 'Evelyn Duarte',
  'Roberto Souza', 'Larissa Almeida', 'Bruno Teixeira', 'Valéria Santos', 'Renato Carvalho',
  'Patrícia Gomes', 'Vinícius Pereira', 'Rosana Freitas', 'Diego Fernandes', 'Alana Nunes',
  'Júlio César', 'Flávia Castro', 'Vitor Oliveira', 'Mariana Lima', 'Ricardo Nascimento',
  'Bárbara Souza', 'Marcelo Pereira', 'Juliana Fernandes', 'André Alves', 'Amanda Santos',
  'Fernando Souza', 'Bianca Lima', 'Carlos Eduardo', 'Letícia Nogueira', 'Samuel Almeida',
  'Raquel Araújo', 'Rodrigo Mendes', 'Camila Lima', 'Pedro Nunes', 'Luciana Souza',
];

export const nomesJogos = [
  'Fortune Tiger', 'Fortune Ox', 'Fortune Mouse', 'Fortune Rabbit', 'Fortune Dragon', 'Cash Mania', 'Jungle Deligth', 'Double Fortune', 'Ganesha Gold', 'Dragon Tiger Luck', 'Bikini Paradise',
];
