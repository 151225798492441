import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Routes from './pages/Routes';

function App() {
  return (
    <Routes />
  );
}

export default App;
