/* eslint-disable no-unused-vars */
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import logo from '../../images/logo-estendido.png';
import logoMinutos from '../../images/logo-final.png';
import Ganhos from './Ganhos';

export default function Header() {
  const history = useHistory();

  return (
    <div
      className="bg-violet-950 flex flex-col items-center w-full"
      style={ { position: 'fixed', width: '100%', top: 0, zIndex: 1000 } }
    >
      <div
        className="flex justify-between items-center p-2 bg-violet-700 text-white w-full"
      >
        <button
          onClick={ () => history.push('/') }
          className="flex justify-center items-center w-full"
        >
          <img
            className="lg:w-30 w-32"
            src={ logoMinutos }
            alt="ficha-cassino"
          />
        </button>
      </div>
      <Ganhos />
    </div>
  );
}
